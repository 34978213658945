import React from 'react';

import { assets } from '../data';

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-1 mb-4 mb-md-5">
        <div className="container">
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <a
                href="https://www.facebook.com/onepayforbusiness"
                target="_blank"
                rel="noreferrer"
                className="social-link d-flex align-items-center justify-content-center me-3"
              >
                <img
                  src={assets.facebook_icon}
                  alt="facebook icon"
                  loading="lazy"
                />
              </a>

              <a
                href="https://www.instagram.com/onepayforbusiness/"
                target="_blank"
                rel="noreferrer"
                className="social-link d-flex align-items-center justify-content-center me-3"
              >
                <img
                  src={assets.instagram_icon}
                  alt="instagram icon"
                  loading="lazy"
                />
              </a>

              <a
                href="https://twitter.com/onepaylk"
                target="_blank"
                rel="noreferrer"
                className="social-link d-flex align-items-center justify-content-center me-3"
              >
                <img
                  src={assets.twitter_icon}
                  alt="twitter icon"
                  loading="lazy"
                />
              </a>

              <a
                href="https://www.linkedin.com/company/68035901/admin/"
                target="_blank"
                rel="noreferrer"
                className="social-link d-flex align-items-center justify-content-center"
              >
                <img
                  src={assets.linkedIn_icon}
                  alt="linkedin icon"
                  loading="lazy"
                />
              </a>
            </div>

            <div className="d-flex align-items-center info">
              <p className="me-4">+94 (11) 702 1540</p>
              <p>info@onepay.lk</p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-2">
        <div className="container">
          <div className="d-flex align-items-center justify-content-center py-4 text-center">
            <p>© 2023 Copyright by OneTicket. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
