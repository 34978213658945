import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import toast from 'react-hot-toast';

import LeftOffCanvasSpecific from '../layouts/LeftOffCanvasSpecific';
import { formatDate } from '../common';
import { environment, assets } from '../data';
// import countries from 'country-data';
import { countries } from 'countries-list';

export default function PayFormSpecific({
  isOpen = false,
  customFields = [],
  eventDetails,
  tickets = [],
  coupenCode = '',
  total = 0,
  close,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [additionalFields, setAdditionalFields] = useState();
  const [jsonData, setJsonData] = useState('');
  const [formData, setFormData] = useState({});
  const [isStepOne, setIsstepOne] = useState(true);
  const [isStepTwo, setIsstepTwo] = useState(false);
  const [isSecondPage, setIsSecondPage] = useState(false);
  const [ticketJson, setTicketJson] = useState([]);
  //const [sendJson, setSendJson] = useState([]);
  const [errorIndex, setErrorIndex] = useState(-1);
  const [assignEmails, setAssignEmails] = useState(
    Array(tickets.length).fill(''),
  );
  const [addBtnDisable, setAddBtnDisabl] = useState(
    Array(tickets.length).fill(true),
  );
  //const [assignEmail, setAssignEmail] = useState('');
  const [errorPin, setErrorPin] = useState('');
  const [isVerify, setVerify] = useState(false);
  const [isYesPMIMember, setYesPMIMember] = useState(true);
  const [isChapterMember, setIsChapterMember] = useState(true);
  const [totalAssignEmails, setTotalAssignEmails] = useState(0);
  const [phoneNumberExtension, setPhoneNumberExtension] = useState('');
  const [availableExtensions, setAvailableExtensions] = useState([]);
  const [selectedExtension, setSelectedExtension] = useState('');

  const handleExtensionChange = (e) => {
    setSelectedExtension(e.target.value);
  };

  useEffect(() => {}, [totalAssignEmails]);

  const handleCustomInputChange = (fieldName, event) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const newAssignEmails = [...assignEmails];
    newAssignEmails[index] = value;
    setAssignEmails(newAssignEmails);
  };
  const truncateString = (str) => {
    return str.length > 20 ? str.slice(0, 17) + '...' : str;
  };
  function deleteAssignees(index) {
    if (index < 0 || index >= ticketJson.length) {
      // Index out of bounds, return the original array
      return;
    }
    const newArray = [
      ...ticketJson.slice(0, index),
      ...ticketJson.slice(index + 1),
    ]; // Remove the object at the specified index
    setTicketJson(newArray);
    setTotalAssignEmails((preNumber) => preNumber - 1);
  }
  function countIdEqualsOne(arr, id, tickect_count, index) {
    if (tickect_count - arr.filter((obj) => obj.id === id).length === 0) {
      //handleAddBtnChange(false, index);
      //toast.error('Oh! This type remaining tickets exceed!');
      return 0;
    }

    return tickect_count - arr.filter((obj) => obj.id === id).length;
  }

  function addAssignees(assign_id, email, index, total) {
    console.log('EMAIL :', email);
    console.log('TICKETS :', ticketJson);
    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (countIdEqualsOne(ticketJson, assign_id, total, index) !== 0) {
      if (!emailPattern.test(email)) {
        setErrorIndex(index);
      } else {
        const obj = {
          id: assign_id,
          email: email,
        };
        setTicketJson((prevData) => [...prevData, obj]);
        setErrorIndex(-1);
        const newAssignEmails = [...assignEmails];
        newAssignEmails[index] = '';
        setAssignEmails(newAssignEmails);
        setTotalAssignEmails((preNumber) => preNumber + 1);
        // ticketJson.forEach((t) => {
        //   if (assign_id === t.ticket_id) {
        //     ticketJson.add(email);
        //   }
        // });
      }
    } else {
      toast.error('Oh! This type remaining tickets exceed!');
    }
  }
  // const handleCustomInputChange = (field_name, event) => {
  //   // const values = [...jsonData];
  //   // values[index] = event.target.value;
  //   // setJsonData(values);
  //   let data = `${field_name} : ${event.target.value},`;
  //   setJsonData(jsonData + data);
  //   console.log(jsonData);
  // };

  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fname: '',
      lname: 'PMI',
      email: '',
      phone: '',
      country: '',
      city: '',
      experience: '',
      companyName: '',
      designation: '',
      linkinProfile: '',
      industry: '',
      gender: 'M',
      dob: '24:04:1996',
      verificationMethod: 'NIC',
      verificationId: 'PMI',
      address: 'PMI',
      isPMIMember: 'Yes',
      membershipId: '',
      is_benifits: 'Yes',
      isChapterMember: 'Yes',
      chapter: '',
      activityHold: '',
      eventAwareness: '',
      moreInfo: 'Yes',
      acceptConditions: false,
    },
    mode: 'onChange',
  });

  const onNextButtonClick = async () => {
    // Trigger form validation
    const isValid = await trigger();
    if (isValid) {
      console.log('Form 1 is valid.:', totalAssignEmails);
      console.log('Form 2 is valid.:', getTicketCount());
      if (totalAssignEmails < getTicketCount()) {
        toast.error('There is assign email missing');
      } else {
        // Proceed to the next step or perform any other action
        console.log('Form is valid. Proceeding to the next step...');
        setIsstepOne(true);
        setIsstepTwo(true);
        setIsSecondPage(true);
      }
    } else {
      // Form is not valid, display errors
      console.log('Form validation failed. Please check for errors.');
    }
  };

  function onSubmit(data) {
    // console.log(data);
    // console.log(tickets);
    console.log(errors);

    if (isLoading || errors === null) {
      return;
    }

    let sendJson = [];

    tickets.forEach((t) => {
      const assignee = [];
      ticketJson.forEach((email) => {
        if (email.id === t.id) {
          assignee.push(email.email);
        }
      });
      let t1 = {
        ticket_id: t.id,
        count: t.count,
        assignees: assignee,
      };
      //setSendJson((prevData) => [...prevData, t1]);
      sendJson.push(t1);
    });

    let body = {
      event_id: eventDetails.event_details,
      customer_first_name: data.fname,
      customer_last_name: data.lname,
      customer_email: data.email,
      customer_phone_no: data.phone,
      customer_address: data.address,
      gender: data.gender,
      dob: data.dob,
      verification_method: data.verificationMethod,
      verification_id: data.verificationId,
      tickets: sendJson,
      coupon_code: coupenCode,
      //additional_fields: jsonData,
      additional_fields: formData,
      is_pmi_event: true, //eventDetails.event_details
      country: data.country,
      city: data.city,
      industry: data.industry,
      company_name: data.companyName,
      designation: data.designation,
      experience: data.experience,
      is_pmi_member: data.isPMIMember === 'Yes' ? true : false,
      pmi_id: data.membershipId,
      is_benefits: data.is_benifits === 'Yes' ? true : false,
      is_chapter_member: data.isChapterMember === 'Yes' ? true : false,
      chapter: data.chapter,
      certification_activity_hold: data.activityHold,
      event_awareness: data.eventAwareness,
      more_information: data.moreInfo === 'Yes' ? true : false,
    };

    console.log(body);
    console.log('ABCD :', data.acceptConditions);
    if (!data.acceptConditions) {
      toast.error('You have to confirm terms and conditions of attendance');
      return;
    }
    setIsLoading(true);

    axios
      .post(`${environment.BASE_URL2}/events/event-transaction/`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // console.log(res);
        const data = res?.data;
        console.log(data);

        if (data?.status === 100) {
          toast.success(
            'Success! You will be redirected to onepay payment gateway',
            { id: 'buyTickets' },
          );
          reset({
            fname: '',
            lname: 'PMI',
            email: '',
            phone: '',
            country: '',
            city: '',
            experience: '',
            companyName: '',
            designation: '',
            linkinProfile: '',
            industry: '',
            gender: 'M',
            dob: '24:04:1996',
            verificationMethod: 'NIC',
            verificationId: 'PMI',
            address: 'PMI',
            isPMIMember: 'Yes',
            membershipId: '',
            is_benifits: 'Yes',
            isChapterMember: 'Yes',
            chapter: '',
            activityHold: '',
            eventAwareness: '',
            moreInfo: 'Yes',
            acceptConditions: false,
          });
          setTimeout(() => {
            window.location.replace(data.data.redirect_url);
          }, 4000);
        } else {
          console.error(data.message || 'Payment failed!');
          toast.error('Oh! Something went wrong!', { id: 'buyTickets' });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Oh! Something went wrong!', { id: 'buyTickets' });
      })
      .finally(() => {
        setIsLoading(false);
        setJsonData('');
      });
  }

  function checkPMIValue() {
    const isPMIValue = getValues('isPMIMember');
    if (isPMIValue === 'Yes') {
      setYesPMIMember(true);
    } else {
      setYesPMIMember(false);
    }
  }

  function checkIsChapter() {
    const isChapterMember = getValues('isChapterMember');
    if (isChapterMember === 'Yes') {
      setIsChapterMember(true);
    } else {
      setIsChapterMember(false);
    }
  }

  function getTicketCount() {
    return tickets.reduce((t, num) => t + num.count, 0);
  }

  function yearOfEx() {
    const vMethod = getValues('experience');

    if (vMethod === 'DEFAULT') {
      return 'Please select a verification method';
    } else {
      return true;
    }
  }

  // check if there are emojis in the address
  function checkAddress() {
    const address = getValues('address');
    const pattern = /\p{Extended_Pictographic}/u;

    if (pattern.test(address)) {
      return "Can't use emojis";
    } else {
      return true;
    }
  }

  // function getCustomFieldsValues() {
  //   let emptyJson = {};
  //   customFields.map((data) => {
  //     emptyJson[data.id] = getValues(`FieldNo${data.id}`);
  //     return null;
  //   });
  //   console.log(emptyJson);
  //   setJsonData(emptyJson);
  //   console.log(jsonData);
  // }

  // console.log(errors);

  return (
    <LeftOffCanvasSpecific
      isOpen={isOpen}
      heading="Fill your details"
      close={close}
    >
      <div className="pay-form-specific">
        <div className="d-flex flex-column flex-sm-row align-items-start total-specific mb-3">
          <div className="col-12 col-sm-6 pe-0 pe-sm-2">
            <h5>{eventDetails?.event_name}</h5>
            <div className="d-flex align-items-center justify-content-start">
              <span className="tickets-count">
                {eventDetails.event_details === 'QX5C118E404233F1032E0'
                  ? '2024/05/31 (Friday), 8:30 AM – 6:00 PM'
                  : eventDetails.event_details === 'UIPA118E4147B2399589F'
                  ? '2024/04/26 (Friday) 6:30 PM'
                  : formatDate(eventDetails?.event_datetime)}
              </span>
            </div>
          </div>
          <div className="col-12 col-sm-6 pe-0 pe-sm-2 mt-2">
            <span className="tickets-count">
              {getTicketCount() || 0}{' '}
              Tickets&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            </span>
            <span className="amount">
              Total : {total.toFixed(2)} {eventDetails?.tickets_currency}
            </span>
          </div>
        </div>
        <div className="stepper-div mb-3">
          <div className="step-01">
            <div
              className={`stepper ${
                isStepOne === true ? 'active' : 'deactive'
              }`}
            >
              1
            </div>
            <div className="stepper-name">Step 1</div>
          </div>
          <div className="dot-line">.................</div>
          <div className="step-02">
            <div
              className={`stepper ${
                isStepTwo === true ? 'active' : 'deactive'
              }`}
            >
              2
            </div>
            <div className="stepper-name">Step 2</div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!isSecondPage ? (
            <div className="row">
              <div className="d-flex flex-column flex-sm-row align-items-start">
                <div className="col-12 col-sm-6 pe-0 pe-sm-2 mb-3">
                  <label>
                    Full Name <span className="required-sign">*</span>
                  </label>

                  <input
                    type="text"
                    className={`form-control ${errors.fname ? 'on-error' : ''}`}
                    placeholder="Write your first name"
                    {...register('fname', {
                      required: {
                        value: true,
                        message: 'Please write your first name',
                      },
                    })}
                  />

                  {errors.fname ? (
                    <p className="error-text">{errors.fname.message}</p>
                  ) : null}
                </div>

                <div className="col-12 col-sm-6 pe-0 ps-sm-2 mb-3">
                  <label>
                    Email address <span className="required-sign">*</span>
                  </label>

                  <input
                    type="text"
                    className={`form-control ${errors.email ? 'on-error' : ''}`}
                    placeholder="Write your email address"
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'Please write your email',
                      },
                    })}
                  />

                  {errors.email ? (
                    <p className="error-text">{errors.email.message}</p>
                  ) : null}
                </div>
              </div>
              <div className="d-flex flex-column flex-sm-row align-items-start">
                <div className="col-12 col-sm-6 ps-0 pe-sm-2 mb-3">
                  <label>
                    Contact number <span className="required-sign">*</span>
                  </label>
                  <div
                    className="form-control d-flex flex-column flex-sm-row align-items-start"
                    style={{ borderRadius: 0, borderColor: '#757D8F' }}
                  >
                    {/* <select
                      className="col-3"
                      style={{ border: 'none', borderStyle: 'none', outline: 'none', margin: 0 }}
                      id="extensionDropdown"
                      value={selectedExtension}
                      onChange={handleExtensionChange}>
                      <option value="+94" defaultValue={+94}>
                        +94
                      </option>
                      {Object.entries(countries).map(([code, country]) => (
                        <option key={code} value={`+${country.phone}`}>{`+${country.phone}`}</option>
                      ))}
                    </select> */}
                    <input
                      type="tel"
                      style={{
                        border: 'none',
                        borderStyle: 'none',
                        outline: 'none',
                        backgroundColor: 'none',
                      }}
                      className={`col-9 ${errors.phone ? 'on-error' : ''}`}
                      placeholder="+94XXXXXXXXXX"
                      {...register('phone', {
                        required: {
                          value: true,
                          message: 'Please write your phone number',
                        },
                        pattern: {
                          value: /^\+?[0-9]{8}[0-9]*$/,
                          message: 'Invalid phone number',
                        },
                      })}
                    />
                  </div>

                  {errors.phone ? (
                    <p className="error-text">{errors.phone.message}</p>
                  ) : null}
                </div>
                <div className="col-12 col-sm-6 pe-0 ps-sm-2 mb-3">
                  <label>
                    Country <span className="required-sign">*</span>
                  </label>

                  <input
                    type="text"
                    className={`form-control ${
                      errors.country ? 'on-error' : ''
                    }`}
                    placeholder="Write your country"
                    {...register('country', {
                      required: {
                        value: true,
                        message: 'Please write your country',
                      },
                    })}
                  />

                  {errors.country ? (
                    <p className="error-text">{errors.country.message}</p>
                  ) : null}
                </div>
              </div>
              <div className="d-flex flex-column flex-sm-row align-items-start">
                <div className="col-12 col-sm-6 ps-0 pe-sm-2 mb-3">
                  <label>
                    City <span className="required-sign">*</span>
                  </label>

                  <input
                    type="text"
                    className={`form-control ${errors.city ? 'on-error' : ''}`}
                    placeholder="Write your city"
                    {...register('city', {
                      required: {
                        value: true,
                        message: 'Please write your cityr',
                      },
                    })}
                  />

                  {errors.city ? (
                    <p className="error-text">{errors.city.message}</p>
                  ) : null}
                </div>
                <div className="col-12 col-sm-6 pe-0 ps-sm-2 mb-3">
                  <label>
                    Years of experience<span className="required-sign">*</span>
                  </label>

                  <select
                    className={`form-select ${
                      errors.experience ? 'on-error' : ''
                    }`}
                    aria-label="Default select example"
                    {...register('experience', {
                      required: {
                        value: true,
                        message: 'Please select year of experience',
                      },
                      validate: {
                        isDefault: yearOfEx,
                      },
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Select year of experience
                    </option>
                    <option value="0-5 years">0-5 years</option>
                    <option value="5-10 years">5-10 years</option>
                    <option value="10-15 years">10-15 years</option>
                    <option value="15-20 years">15-20 years</option>
                    <option value="20-25 years">20-25 years</option>
                    <option value="25+ years">25+ years</option>
                  </select>

                  {errors.experience ? (
                    <p className="error-text">{errors.experience.message}</p>
                  ) : null}
                </div>
              </div>

              <div className="d-flex flex-column flex-sm-row align-items-start">
                <div className="col-12 col-sm-6 ps-0 pe-sm-2 mb-3">
                  <label>
                    Company name <span className="required-sign">*</span>
                  </label>

                  <input
                    type="text"
                    className={`form-control ${
                      errors.companyName ? 'on-error' : ''
                    }`}
                    placeholder="Write your company name"
                    {...register('companyName', {
                      required: {
                        value: true,
                        message: 'Please select your company name',
                      },
                    })}
                  />

                  {errors.companyName ? (
                    <p className="error-text">{errors.companyName.message}</p>
                  ) : null}
                </div>
                <div className="col-12 col-sm-6 pe-0 ps-sm-2 mb-3">
                  <label>
                    Designation<span className="required-sign">*</span>
                  </label>

                  <input
                    type="text"
                    className={`form-control ${
                      errors.designation ? 'on-error' : ''
                    }`}
                    placeholder="Write your designation"
                    {...register('designation', {
                      required: {
                        value: true,
                        message: 'Please write your designation',
                      },
                    })}
                  />

                  {errors.designation ? (
                    <p className="error-text">{errors.designation.message}</p>
                  ) : null}
                </div>
              </div>

              <div className="d-flex flex-column flex-sm-row align-items-start">
                <div className="col-12 col-sm-6 pe-0 pe-sm-2 mb-3">
                  <label>Linkedin profile</label>

                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="Write your linkedin url"
                    {...register('linkinProfile', {})}
                  />

                  {/* {errors.linkinProfile ? <p className="error-text">{errors.linkinProfile.message}</p> : null} */}
                </div>
                <div className="col-12 col-sm-6 pe-0 ps-sm-2 mb-3">
                  <label>
                    Industry<span className="required-sign">*</span>
                  </label>

                  <select
                    className={`form-select ${
                      errors.industry ? 'on-error' : ''
                    }`}
                    aria-label="Default select example"
                    {...register('industry', {
                      required: {
                        value: true,
                        message: 'Please select your industry',
                      },
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Select your industry
                    </option>
                    <option value="Academiya or training">
                      Academia and training
                    </option>
                    <option value="Aerospace">Aerospace</option>
                    <option value="Automobile">Automobile</option>
                    <option value="BFSI">BFSI</option>
                    <option value="Construction">Construction</option>
                    <option value="Consulting">Consulting</option>
                    <option value="Defencse">Defence</option>
                    <option value="E-Commerce">E-Commerce</option>
                    <option value="Edtech">Edtech</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Entertainment">Entertainment</option>
                    <option value="Health Care">Health Care</option>
                    <option value="Infrastructuring">Infrastructure</option>
                    <option value="IT and IELTS">IT and ITES</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Pharma">Pharma</option>
                    <option value="Power and Energy">Power and Energy</option>
                    <option value="Service">Service</option>
                    <option value="Others">Others</option>
                  </select>

                  {errors.industry ? (
                    <p className="error-text">{errors.industry.message}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 mb-3">
                <label>
                  Please make sure to enter a valid email address in the
                  participant list. The QR code for verification will be sent to
                  the email you provide, so please double-check it.
                </label>
              </div>
              {tickets?.map((ticket, index) => (
                <div key={index} className="col-12 col-sm-12 pe-0 pe-sm-4 mb-3">
                  <label className="mb-4">
                    Participant emails{' '}
                    <span className="required-sign pe-sm-2">*</span>
                    <span className="ticket_price">
                      {/* {ticket.ticket_visualize_amount === '' ? 'Free' : ticket.ticket_visualize_amount.split('.')[0]}{' '} */}
                      {ticket.ticket_name} Tickets
                    </span>
                  </label>
                  <br></br>
                  <label className="mb-2">
                    Participant email address{' '}
                    <span className="required-sign">*</span>
                  </label>
                  <div className="d-flex flex-row flex-sm-row align-items-start">
                    <div className="col-9 col-sm-10">
                      <input
                        type="text"
                        className={`form-control`}
                        placeholder="Write your email address"
                        value={assignEmails[index]}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                      {index === errorIndex && (
                        <div style={{ color: 'red' }}>
                          Please enter a valid email address
                        </div>
                      )}
                    </div>
                    <div
                      className="col-3 col-sm-2 primary-btn2 d-flex align-items-center justify-content-center ms-sm-2"
                      disabled={addBtnDisable[index]}
                      onClick={() => {
                        addAssignees(
                          ticket.id,
                          assignEmails[index],
                          index,
                          ticket.count,
                        );
                      }}
                    >
                      Add
                    </div>
                  </div>
                  <div className="email-grid">
                    {ticketJson?.map(
                      (email, i) =>
                        email.id === ticket.id && (
                          <div key={i} className="email-show">
                            {truncateString(email.email)}
                            <button
                              className="cut-btn-email"
                              onClick={() => deleteAssignees(i)}
                            >
                              <img
                                src={assets.close_icon2}
                                className="cut-btn-email"
                                alt="close-coperate offcanvas icon"
                                loading="lazy"
                              />
                            </button>
                          </div>
                        ),
                    )}
                  </div>
                  <label className="mt-2 mb-2">
                    <span className="remaining-emails">
                      {countIdEqualsOne(
                        ticketJson,
                        ticket.id,
                        ticket.count,
                        index,
                      )}{' '}
                      Emails remaining
                    </span>
                  </label>
                </div>
              ))}
              {/* <div className="col-12 mb-3">
                <label>Address</label>

                <textarea
                  className={`form-control ${errors.address ? 'on-error' : ''}`}
                  placeholder="Write your address"
                  {...register('address', {
                    //required: { value: true, message: 'Please write your address' },
                    validate: { imoji: checkAddress },
                  })}
                  rows={5}
                />

                {errors.address ? <p className="error-text">{errors.address.message}</p> : null}
              </div> */}
              {customFields.map((field) => (
                <div key={field.id} className="col-12 mb-3">
                  <label>{field.field_name}</label>

                  <input
                    key={field.id}
                    type="text"
                    className={`form-control ${errors.phone ? 'on-error' : ''}`}
                    placeholder="Write here"
                    {...register(`FieldNo${field.id}`, {
                      required: { value: false, message: '' },
                      pattern: { value: field.field_regex, message: '' },
                    })}
                    onChange={(event) =>
                      handleCustomInputChange(field.field_name, event)
                    }
                  />

                  {errors.phone ? (
                    <p className="error-text">{errors.phone.message}</p>
                  ) : null}
                </div>
              ))}

              <div className="d-flex flex-column flex-sm-row align-items-center justify-content-end">
                <div className="col-12 col-sm-3 pe-0">
                  <img
                    src={assets.onepay_pg_logo}
                    alt="payment options"
                    loading="lazy"
                  />
                </div>

                <div className="col-12 col-sm-3 pe-0">
                  <img
                    src={assets.logos_img}
                    alt="payment options"
                    loading="lazy"
                  />
                </div>
                <div className="col-12 col-sm-6 pe-0 d-flex align-items-center justify-content-end mt-2">
                  {/* <button className="pay-btn btn primary-btn" type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="visually-hidden">Loading...</span>
                      </>
                    ) : (
                      'Pay with Onepay'
                    )}
                  </button> */}
                  <dev
                    className="pay-btn btn primary-btn"
                    onClick={() => {
                      onNextButtonClick();
                    }}
                  >
                    Next
                  </dev>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="d-flex flex-column flex-sm-row align-items-start">
                <div className="col-12 col-sm-6 mb-3">
                  <label>
                    PMI Member <span className="required-sign">*</span>
                  </label>

                  <div className="d-flex align-items-center">
                    <div className="col-4 col-sm-6 col-md-4 pe-2">
                      <input
                        type="radio"
                        className={`form-check-input me-3 ${
                          errors.isPMIMember ? 'on-error' : ''
                        }`}
                        style={{ cursor: 'pointer' }}
                        id="Yes"
                        {...register('isPMIMember', {
                          required: {
                            value: true,
                            message: 'Please select PMI member status',
                          },
                          validate: {
                            isDefault: checkPMIValue,
                          },
                        })}
                        value="Yes"
                      />
                      <label htmlFor="Yes">Yes</label>
                    </div>

                    <div className="col-4 col-sm-6 col-md-4 ps-2">
                      <input
                        type="radio"
                        className={`form-check-input me-3 ${
                          errors.isPMIMember ? 'on-error' : ''
                        }`}
                        style={{ cursor: 'pointer' }}
                        id="No"
                        {...register('isPMIMember', {
                          validate: {
                            isDefault: checkPMIValue,
                          },
                        })}
                        value="No"
                      />
                      <label htmlFor="No">No</label>
                    </div>
                  </div>

                  {errors.isPMIMember ? (
                    <p className="error-text">{errors.isPMIMember.message}</p>
                  ) : null}
                </div>
              </div>
              {isYesPMIMember ? (
                <>
                  <div className="d-flex flex-column flex-sm-row align-items-start">
                    <div className="col-12 col-sm-6 ps-0 pe-sm-2 mb-3">
                      <label>
                        PMI Membership id{' '}
                        <span className="required-sign">*</span>
                      </label>

                      <input
                        type="text"
                        className={`form-control ${
                          errors.phone ? 'on-error' : ''
                        }`}
                        placeholder="Write your membership id"
                        {...register('membershipId', {
                          required: {
                            value: true,
                            message: 'Please write your membership id',
                          },
                        })}
                      />

                      {errors.membershipId ? (
                        <p className="error-text">
                          {errors.membershipId.message}
                        </p>
                      ) : null}
                    </div>
                    <div className="col-12 col-sm-6 ps-0 ps-sm-2 mb-3">
                      <label>
                        Are you a Chapter Member ?{' '}
                        <span className="required-sign">*</span>
                      </label>

                      <div className="d-flex align-items-center">
                        <div className="col-4 col-sm-6 col-md-4 pe-2">
                          <input
                            type="radio"
                            className={`form-check-input me-3 ${
                              errors.isChapterMember ? 'on-error' : ''
                            }`}
                            style={{ cursor: 'pointer' }}
                            id="Yes"
                            {...register('isChapterMember', {
                              required: {
                                value: true,
                                message: 'Please select chapter member status',
                              },
                              validate: {
                                isDefault: checkIsChapter,
                              },
                            })}
                            value="Yes"
                          />
                          <label htmlFor="Yes">Yes</label>
                        </div>

                        <div className="col-4 col-sm-6 col-md-4 ps-2">
                          <input
                            type="radio"
                            className={`form-check-input me-3 ${
                              errors.isChapterMember ? 'on-error' : ''
                            }`}
                            style={{ cursor: 'pointer' }}
                            id="No"
                            {...register('isChapterMember')}
                            value="No"
                          />
                          <label htmlFor="No">No</label>
                        </div>
                      </div>

                      {errors.isChapterMember ? (
                        <p className="error-text">
                          {errors.isChapterMember.message}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  {isChapterMember ? (
                    <div className="d-flex flex-column flex-sm-row align-items-start">
                      <div className="col-12 col-sm-6 ps-0 pe-sm-2 mb-3">
                        <label>
                          Please mention Chapter name{' '}
                          <span className="required-sign">*</span>
                        </label>

                        <input
                          type="text"
                          className={`form-control ${
                            errors.chapter ? 'on-error' : ''
                          }`}
                          placeholder="Write your chapter"
                          {...register('chapter', {
                            required: {
                              value: true,
                              message: 'Please write your chapter',
                            },
                          })}
                        />

                        {errors.chapter ? (
                          <p className="error-text">{errors.chapter.message}</p>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <div className="col-12 mb-3">
                    <label>
                      Would you like to be contacted about PMI Membership
                      benefits?
                    </label>
                  </div>
                  <div className="d-flex flex-column flex-sm-row align-items-start">
                    <div className="col-12 col-sm-6 mb-3">
                      {/* <label>
                  Are you open to receiving special offers and informational communication from the conference sponsors
                  and partners
                  </label> */}

                      <div className="d-flex align-items-center">
                        <div className="col-4 col-sm-6 col-md-4 pe-2">
                          <input
                            type="radio"
                            className={`form-check-input me-3 ${
                              errors.is_benifits ? 'on-error' : ''
                            }`}
                            style={{ cursor: 'pointer' }}
                            id="Yes"
                            {...register('is_benifits', {})}
                            value="Yes"
                          />
                          <label htmlFor="Yes">Yes</label>
                        </div>

                        <div className="col-4 col-sm-6 col-md-4 ps-2">
                          <input
                            type="radio"
                            className={`form-check-input me-3 ${
                              errors.is_benifits ? 'on-error' : ''
                            }`}
                            style={{ cursor: 'pointer' }}
                            id="No"
                            {...register('is_benifits')}
                            value="No"
                          />
                          <label htmlFor="No">No</label>
                        </div>
                      </div>

                      {errors.is_benifits ? (
                        <p className="error-text">
                          {errors.is_benifits.message}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </>
              )}

              <div className="d-flex flex-column flex-sm-row align-items-start">
                <div className="col-12 col-sm-6 pe-0 pe-sm-2 mb-3">
                  <label>
                    Please indicate which certifications you actively hold.
                  </label>

                  <select
                    className={`form-select `}
                    aria-label="Default select example"
                    {...register('activityHold', {
                      // required: { value: true, message: 'Please certification you actively hold' },
                      // validate: {
                      //   isDefault: checkVMethod,
                      // },
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Select certifications actively hold
                    </option>
                    <option value="CAPM">CAPM</option>
                    <option value="PMP">PMP</option>
                    <option value="PgMP">PgMP</option>
                    <option value="PfMP">PfMP</option>
                    <option value="PMI-ACP">PMI-ACP</option>
                    <option value="PMI-PBA">PMI-PBA</option>
                    <option value="PMI-SP">PMI-SP</option>
                    <option value="PMI-RMP">PMI-RMP</option>
                    <option value="DA">DA</option>
                    <option value="PMI-CP">PMI-CP(Construction)</option>
                    <option value="None">None</option>
                  </select>

                  {/* {errors.activityHold ? <p className="error-text">{errors.activityHold.message}</p> : null} */}
                </div>
                <div className="col-12 col-sm-6 pe-0 pe-sm-2 mb-3">
                  <label className="mb-3">
                    How did you hear about this event?
                  </label>

                  <select
                    className={`form-select mt-2`}
                    aria-label="Default select example"
                    {...register('eventAwareness', {
                      // required: { value: true, message: 'Please select event awaerness' },
                      // validate: {
                      //   isDefault: checkVMethod,
                      // },
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      How did you hear about this
                    </option>
                    <option value="Emailer">Emailer</option>
                    <option value="LinkedIn">LinkedIn</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Referral">Referral</option>
                    <option value="Print Advertisement">
                      Print Advertisement
                    </option>
                  </select>

                  {/* {errors.eventAwareness ? <p className="error-text">{errors.eventAwareness.message}</p> : null} */}
                </div>
              </div>
              <div className="col-12 mb-3">
                <label>
                  Are you open to receiving special offers and informational
                  communication from the conference sponsors and partners
                </label>
              </div>
              <div className="d-flex flex-column flex-sm-row align-items-start">
                <div className="col-12 col-sm-6 mb-3">
                  {/* <label>
                  Are you open to receiving special offers and informational communication from the conference sponsors
                  and partners
                  </label> */}

                  <div className="d-flex align-items-center">
                    <div className="col-4 col-sm-6 col-md-4 pe-2">
                      <input
                        type="radio"
                        className={`form-check-input me-3 ${
                          errors.moreInfo ? 'on-error' : ''
                        }`}
                        style={{ cursor: 'pointer' }}
                        id="Yes"
                        {...register('moreInfo', {})}
                        value="Yes"
                      />
                      <label htmlFor="Yes">Yes</label>
                    </div>

                    <div className="col-4 col-sm-6 col-md-4 ps-2">
                      <input
                        type="radio"
                        className={`form-check-input me-3 ${
                          errors.moreInfo ? 'on-error' : ''
                        }`}
                        style={{ cursor: 'pointer' }}
                        id="No"
                        {...register('moreInfo')}
                        value="No"
                      />
                      <label htmlFor="No">No</label>
                    </div>
                  </div>

                  {errors.moreInfo ? (
                    <p className="error-text">{errors.moreInfo.message}</p>
                  ) : null}
                </div>
              </div>
              <div className="d-flex flex-column flex-sm-row align-items-start">
                <div className="col-12 mb-3 d-flex flex-column flex-sm-row align-items-start">
                  {/* <label>
                  Gender <span className="required-sign">*</span>
                  </label> */}
                  <input
                    type="checkbox"
                    className={`form-check-input me-3`}
                    style={{ cursor: 'pointer' }}
                    id="acceptConditions"
                    {...register('acceptConditions', {
                      // required: { value: true, message: 'Please confirm the Terms and Conditions of attendance' },
                    })}
                    //value="No"
                  />
                  <label htmlFor="acceptConditions">
                    I have read the{' '}
                    <a
                      style={{ textDecoration: 'underline' }}
                      href="https://onepayserviceimages.s3.ap-southeast-1.amazonaws.com/Participation+Terms+and+Conditions_PMRC24.pdf"
                      target="blank"
                    >
                      Terms and Conditions of attendance
                    </a>
                    .
                  </label>

                  {/* {errors.acceptConditions ? <p className="error-text">{errors.acceptConditions.message}</p> : null} */}
                </div>
              </div>

              <div className="d-flex flex-column flex-sm-row align-items-center justify-content-end">
                <div className="col-12 col-sm-2 pe-0">
                  <img
                    src={assets.onepay_pg_logo}
                    alt="payment options"
                    loading="lazy"
                  />
                </div>

                <div className="col-12 col-sm-2 pe-0">
                  <img
                    src={assets.logos_img}
                    alt="payment options"
                    loading="lazy"
                  />
                </div>
                <div className="col-12 col-sm-8 pe-0 d-flex align-items-center justify-content-end mt-2">
                  <button
                    className="next-btn btn"
                    onClick={() => {
                      setIsstepOne(true);
                      setIsstepTwo(false);
                      setIsSecondPage(false);
                    }}
                  >
                    Back
                  </button>
                  <button
                    className="pay-btn btn primary-btn"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Loading...</span>
                      </>
                    ) : (
                      'Pay with Onepay'
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}{' '}
        </form>
      </div>
    </LeftOffCanvasSpecific>
  );
}
