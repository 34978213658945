import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { set, useForm } from 'react-hook-form';
import axios from 'axios';
import toast from 'react-hot-toast';

import { environment, assets } from '../data';
import close_img from '../assets/img/close-fill.png';

export default function Header() {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullname: '',
      email: '',
      phone: '',
      email_msg: '',
    },
    mode: 'onChange',
  });
  function checkMsg() {
    const message = getValues('email_msg');
    const pattern = /\p{Extended_Pictographic}/u;

    if (pattern.test(message)) {
      return "Can't use emojis";
    } else {
      return true;
    }
  }

  function onSubmit(data) {
    let body = {
      name: data.fullname,
      email: data.email,
      phone: '(OneTicket) ' + data.phone,
      message: data.email_msg,
    };

    console.log(body);

    axios
      .post(`${environment.BASE_URL2}/merchant/contact_form/`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // console.log(res);
        const data = res?.data;
        console.log(data);

        if (data?.status === 100 && data.message === 'success') {
          toast.success(
            'Success! Your request is successfully submitted. Our team will get back to you soon',
            {
              id: 'sendMail',
            },
          );
          setPopupVisible(false);
        } else {
          console.error(data.message || 'Submit failed!');
          toast.error('Oh! Something went wrong!', { id: 'sendMail' });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Oh! Something went wrong!', { id: 'buyTickets' });
      })
      .finally(() => {});
  }

  return (
    <div className="header">
      <div className="d-flex align-items-center justify-content-between py-3">
        <div className="logo">
          <Link to="/">
            <img src={assets.logo} alt="onepay logo" loading="lazy" />
          </Link>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div
            className="flex-column flex-sm-row align-items-start"
            style={{ cursor: 'pointer' }}
          >
            <p onClick={() => setPopupVisible(true)}>Contact us </p>
          </div>
          {/* <div className="flex-column flex-sm-row align-items-start">
            <p>info@onepay.lk</p>
          </div> */}
        </div>
      </div>
      {isPopupVisible && (
        <div className="confirmation-dialog">
          <div className="d-flex align-items-center justify-content-end">
            <img
              style={{ width: 25, cursor: 'pointer' }}
              src={close_img}
              onClick={() => setPopupVisible(false)}
            />
          </div>
          <div>
            <p className="d-flex align-items-center justify-content-center say_hello">
              Say Hello...
            </p>
          </div>
          <div className="row d-flex align-items-center justify-content-center">
            <div className="reach_out d-flex align-items-center justify-content-center">
              Reach out to our team anytime, any day
            </div>
            <div className="reach_out d-flex align-items-center justify-content-center">
              of the year!{' '}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mt-5 pr-3">
              <div className="d-flex flex-column flex-sm-row align-items-start">
                <div
                  className="col-12 col-sm-6 pe-0 pe-sm-2 mb-3"
                  style={{ marginRight: '0.4rem' }}
                >
                  <label>
                    Full Name <span className="required-sign">*</span>
                  </label>

                  <input
                    type="text"
                    className={`form-control ${
                      errors.fullname ? 'on-error' : ''
                    }`}
                    placeholder="Write your first name"
                    {...register('fullname', {
                      required: {
                        value: true,
                        message: 'Write your full name',
                      },
                    })}
                  />

                  {errors.fullname ? (
                    <p className="error-text">{errors.fullname.message}</p>
                  ) : null}
                </div>
                <div className="col-12 col-sm-6 pe-0 pe-sm-2 mb-3">
                  <label>
                    Phone Number <span className="required-sign">*</span>
                  </label>

                  <input
                    type="text"
                    className={`form-control ${errors.phone ? 'on-error' : ''}`}
                    placeholder="Write your phone number"
                    {...register('phone', {
                      required: {
                        value: true,
                        message: 'Write your full name',
                      },
                    })}
                  />

                  {errors.phone ? (
                    <p className="error-text">{errors.phone.message}</p>
                  ) : null}
                </div>
              </div>
              <div className="col-12 mb-3">
                <label>
                  Email address <span className="required-sign">*</span>
                </label>

                <input
                  type="text"
                  className={`form-control ${errors.email ? 'on-error' : ''}`}
                  placeholder="Write your email adress"
                  {...register('email', {
                    required: { value: true, message: 'Write your full name' },
                  })}
                />

                {errors.email ? (
                  <p className="error-text">{errors.email.message}</p>
                ) : null}
              </div>
              <div className="col-12 mb-3">
                <label>
                  Message <span className="required-sign">*</span>
                </label>

                <textarea
                  className={`form-control ${
                    errors.email_msg ? 'on-error' : ''
                  }`}
                  placeholder="Write your message"
                  {...register('email_msg', {
                    required: { value: true, message: 'Write your message' },
                    validate: { imoji: checkMsg },
                  })}
                  rows={7}
                />

                {errors.email_msg ? (
                  <p className="error-text">{errors.email_msg.message}</p>
                ) : null}
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <button className="pay-btn btn primary-btn" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {isPopupVisible && <div className="confirmation-overlay"></div>}
    </div>
  );
}
