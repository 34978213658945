// import { useEffect } from 'react';
// import Layout from './layouts/Layout';
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   useLocation,
// } from 'react-router-dom';
// import { Toaster } from 'react-hot-toast';

// import Cart from './pages/Cart';
// import NotFound from './pages/NotFound';
// import Events from './pages/Events';
// import CoperateUserCart from './pages/CoperateUserCart';
// import CoperateUserCartSpecific from './pages/CoperateUserCartSpecific';
// import { MetaProvider, useMeta } from './MetaContext';
// import MetaUpdater from './MetaUpdater';

// function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

// function App() {
//   return (
//     <MetaProvider>
//       <MetaUpdater />

//       <Router>
//         <ScrollToTop />

//         <Layout>
//           <div className="App">
//             <Routes>
//               <Route
//                 exact
//                 path="/:event_id/:transaction_id"
//                 element={<Cart />}
//               ></Route>
//               <Route exact path="/:event_id" element={<Cart />}></Route>
//               <Route
//                 exact
//                 path="/coperate/:event_id/ticket/:assignee_id"
//                 element={<CoperateUserCartSpecific />}
//               ></Route>
//               <Route exact path="/" element={<Events />}></Route>
//               <Route exact path="/notfound" element={<NotFound />}></Route>
//             </Routes>
//           </div>

//           <Toaster
//             toastOptions={{
//               duration: 5000,
//               position: 'top-center',
//               style: {
//                 color: '#051b13',
//                 fontFamily: 'Poppins, sans-serif',
//                 fontWeight: 500,
//                 fontSize: '0.875rem',
//                 padding: '1rem',
//               },
//             }}
//           />
//         </Layout>
//       </Router>
//     </MetaProvider>
//   );
// }

// export default App;

import { useEffect } from 'react';
import Layout from './layouts/Layout';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import Cart from './pages/Cart';
import NotFound from './pages/NotFound';
import Events from './pages/Events';
import CoperateUserCart from './pages/CoperateUserCart';
import CoperateUserCartSpecific from './pages/CoperateUserCartSpecific';
import { MetaProvider, useMeta } from './MetaContext';
import MetaUpdater from './MetaUpdater';
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => (
  <MetaProvider>
    <Router>
      <MetaUpdater />
      <ScrollToTop />
      <Layout>
        <div className="App">
          <Routes>
            <Route exact path="/:event_id/:transaction_id" element={<Cart />} />
            <Route exact path="/:event_id" element={<Cart />} />
            <Route
              exact
              path="/coperate/:event_id/ticket/:assignee_id"
              element={<CoperateUserCartSpecific />}
            />
            <Route exact path="/" element={<Events />} />
            <Route exact path="/notfound" element={<NotFound />} />
          </Routes>
        </div>
        <Toaster
          toastOptions={{
            duration: 5000,
            position: 'top-center',
            style: {
              color: '#051b13',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500,
              fontSize: '0.875rem',
              padding: '1rem',
            },
          }}
        />
      </Layout>
    </Router>
  </MetaProvider>
);

export default App;
